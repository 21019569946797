<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-position :items="items" @editForm="editForm"/>
            <modal-position @refresh="refresh" />
            <modal-position-edit @refresh="refresh" :id="id"/>
        </div>
            <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import TablePosition from '@/views/component/Table/tablePosition.vue'
import ModalPosition from '@/views/component/Modal/ModalPosition/modalPosition.vue'
import modalPositionEdit from '@/views/component/Modal/ModalPosition/modalPositionEdit.vue'
export default {
    components: {
        TablePosition,
        ModalPosition,
        modalPositionEdit,
    },
    data(){
        return {
            items: [],
            showPreloader: false,
            id: undefined,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
                { key: 'accesses', label: 'Доступы', sortable: true, thStyle: {width: '150px'}}
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods:{
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`position`)
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('positionEdit')
        },
        sendToParent(tableData){
            this.items = tableData
            this.$store.commit('pageData/setdataCount', this.items.length)
        },
       openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.items = res.data
                        this.$store.commit('pageData/setdataCount', this.items.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>