<template>
    <div>
        <b-modal id="positionEdit" title="Изменить данные должности" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="opened">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование</label>
                        <b-form-input v-model="item.name" id="basicInput" placeholder="Наименование"/>
                    </b-form-group>    
                </b-col>
                <b-col>
                   <b-form-checkbox v-model="item.is_active" class="custom-control-primary">
                    {{ item.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
                <b-col cols="12">
                    <label>Разделы</label>
                   <v-select
                        v-model="item.access_routes"
                        label="name"
                        placeholder="Разделы"
                        multiple
                        :options="access_routes"
                        class="select-size-md"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="convertData">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            item: {
                name: undefined,
                is_active: false,
            },
            access_routes: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        editPosition(positionObj){
            this.submitButtonDisabled = true
            this.$http
                .patch(`position/${this.id}`, positionObj)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.$bvModal.hide('positionEdit')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        convertData(){
            this.item.accesses = undefined
            let positionObj = []

            positionObj = JSON.parse(JSON.stringify(this.item))

            this.item.access_routes.forEach(el => {
                positionObj.access_routes.push({id: el.id})
            });
            positionObj.access_routes = JSON.stringify(positionObj.access_routes) 
            this.editPosition(positionObj)
        },
        opened(){
            this.$http
                .get(`position/${this.id}/edit`)
                .then(res => {
                    this.item = res.data
            })
            this.$http
                .get('position/access-routes')
                .then(res => {
                    this.access_routes = res.data
                })
        },
        closeModal(){
            this.$bvModal.hide('positionEdit')
        }
    },
}
</script>